<template>
  <!--    OtherIncome Form-->
  <validation-observer
    ref="otherIncomeForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateOtherIncomeMode?'Update':'Create'} Other Income`"
      :show="otherIncomeModalOpened"
      :show-overlay="deletingOtherIncome"
      size="md"
      @onClose="closeOtherIncomeModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedOtherIncomeId"
            :options="otherIncomes"
            label-name="title"
            value-name="id"
            placeholder="Other Income"
            @input="populateOtherIncomeForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteOtherIncome()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
                  variant="flat-danger"
                  icon="XIcon"
                  label=""
                  tool-tip-text="Clear Form"
                  @onClick="resetOtherIncomeForm()"
          />
        </div>
      </b-row>
      <lenon-input
        v-model="otherIncome.title"
        name="title"
        placeholder="Title, eg. Donation from GES"
        rules="required"
        label="Title"
      />
      <lenon-select
          v-model="otherIncome.income_category_id"
          name="title"
          placeholder="Select Category"
          label="Expense Category"
          label-name="name"
          value-name="id"
          :options="categories"
      />
      <lenon-text-area
        v-model="otherIncome.details"
        name="details"
        placeholder="Income Details, eg. Any relevant information you want to add"
        rules="required"
        label="Income Details"
      />
      <lenon-input
        v-model="otherIncome.amount"
        name="amount"
        placeholder="Enter Amount"
        rules="required|numeric"
        label="Amount"
      />
      <lenon-date-picker
        v-model="otherIncome.date"
        name="date"
        label="Income Date"
        placeholder="Income Date"
        rules="required"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeOtherIncomeModal()"
          />
          <lenon-button
            :label="updateOtherIncomeMode?'Update':'Create'"
            :disabled="invalid"
            :loading="otherIncomeLoading"
            loading-text="Loading..."
            @onClick="updateOtherIncomeMode?updateOtherIncome():createOtherIncome()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_OTHER_INCOME_M,
  DELETE_OTHER_INCOME_M,
  UPDATE_OTHER_INCOME_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'

export default {
  name: 'OtherIncomeSetup',
  components: {
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      deletingOtherIncome: false,
      otherIncomeModalOpened: false,
      updateOtherIncomeMode: false,
      otherIncomeLoading: false,
      selectedOtherIncomeId: null,
      otherIncome: {
        id: null,
        title: null,
        income_category_id: null,
        details: null,
        amount: null,
        date: null,
      },
    }
  },
  computed: {
    categories() {
      return this.$store.getters['earnings/categories']
    },
    otherIncomes() {
      return this.$store.getters['earnings/otherIncome']
    },
    selectedOtherIncome(){
      return this
              .otherIncomes.find(g => +g.id === +this.selectedOtherIncomeId)
    }
  },
  watch: {
    modalOpened(opened) {
      this.otherIncomeModalOpened = opened
    },
    selectedOtherIncomeId(id) {
      if (!id) {
        this.resetOtherIncomeForm()
      }
    },
  },
  methods: {
    resetOtherIncomeForm() {
      this.updateOtherIncomeMode = false
      this.otherIncome = {
        id: null,
        title: null,
        income_category_id: null,
        details: null,
        amount: null,
        date: null,
      }
      this.selectedOtherIncomeId = null
      this.$refs.otherIncomeForm.reset()
    },
    populateOtherIncomeForm(oi) {
      if (this.selectedOtherIncome) {
        this.updateOtherIncomeMode = true
          this.otherIncome = {
            ...this.selectedOtherIncome,
          }
          // eslint-disable-next-line no-underscore-dangle
          delete this.otherIncome.__typename
        } else {
          this.resetOtherIncomeForm()
        }
    },
    closeOtherIncomeModal() {
      this.otherIncomeModalOpened = false
      this.$emit('modalClosed')
    },
    updateOtherIncome() {
      this.error = {}
      if (!this.otherIncome.id) {
        return
      }
      this.otherIncomeLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_OTHER_INCOME_M,
        variables: {
          input: {
            ...this.otherIncome,
            amount: +this.otherIncome.amount,
          },
        },
      })
        .then(res => {
          this.otherIncomeLoading = false
          this.showSuccess('Updated other income successfully')
          this.$store.commit('earnings/updateOtherIncome', res.data.updateOtherIncome)
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to update other income')
          this.otherIncomeLoading = false
        })
    },
    createOtherIncome() {
      this.error = {}
      this.otherIncomeLoading = true
      this.$apollo.mutate({
        mutation: CREATE_OTHER_INCOME_M,
        variables: {
          input: {
            ...this.otherIncome,
            amount: +this.otherIncome.amount,
          },
        },
      })
        .then(res => {
          this.showSuccess('Created other income successfully')
          this.otherIncomeLoading = false
          this.$store.commit('earnings/addOtherIncome', res.data.createOtherIncome)
          this.resetOtherIncomeForm()
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to create other income')
          this.otherIncomeLoading = false
        })
    },
    deleteOtherIncome() {
      if (!this.selectedOtherIncomeId) {
        this.showInfo('Please select a other income ')
        return
      }
      this.deletingOtherIncome = true
      this.$apollo.mutate({
        mutation: DELETE_OTHER_INCOME_M,
        variables: { id: this.selectedOtherIncomeId },
      })
        .then(() => {
          this.deletingOtherIncome = false
          this.showSuccess('Deleted other income successfully')
          this.$store.commit('earnings/removeOtherIncome', this.selectedOtherIncomeId)
          this.selectedOtherIncomeId = null
          this.resetOtherIncomeForm()
        })
        .catch(err => {
          logData(err)
          this.showError('Failed to delete other income')
          this.deletingOtherIncome = false
        })
    },
  },
}
</script>
